// @flow
function isTouchDevice() {
  if (!window) {
    return;
  }

  return (
    'ontouchstart' in window ||
    window.navigator.MaxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0
  );
}

function closeMenuFactory(targetMenu) {
  function closeMenu(e) {
    // remove event listener
    e.target.removeEventListener(e.type, closeMenu);

    targetMenu.classList.remove('active');
  }

  return closeMenu;
}

document.addEventListener('DOMContentLoaded', function() {
  if (isTouchDevice()) {
    Rails.$('[data-toggle-top-nav]').forEach(function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault();
      });
    });
  }

  Rails.$('[data-toggle-top-nav]').forEach(node =>
    node.addEventListener('mouseenter', function(e) {
      var target = e.target;
      var targetMenuName = target.getAttribute('data-toggle-top-nav');
      var targetMenu = Rails.$(
        '[data-responsive-top-nav=' + targetMenuName + ']'
      )[0];

      if (targetMenu) {
        const parent = targetMenu.parentElement;

        Rails.$('[data-responsive-top-nav].active').forEach(node =>
          node.classList.remove('active')
        );

        targetMenu.classList.add('active');

        const closeMenu = closeMenuFactory(targetMenu);
        parent.addEventListener('mouseleave', closeMenu);
      }
    })
  );
});
